.landing-wrapper {
  position:relative;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;

  .down-arrow {
    position:absolute;
    bottom:150px;
    cursor:pointer;

    img{
      width:75px;
      height:auto;
    }
  }

  .tagline{
    top:60%;
    position:absolute;

    p {
      font-size: 75px;
      font-weight: 600;
      color: #7D868C;
      font-family:proxima-nova;
    }
    .period{
      color:#f05b2a;
    }
  }

  .scr-logo {
    left: calc(50% - 200px);
    top: 40%;
    position: absolute;
    width: 400px;
    text-align: center;
  }

  .scr-logo .saberin-text-img{
    width:100%;
    margin-bottom:25px;
  }
  .scr-logo .creative-text-img{
    width:80%;
  }
  .scr-logo .scr-header-logo{
    display:none;
  }
  .scr-logo .saberin-creative-logo{
    width:35%;
    margin-bottom:25px;
    position:absolute;
    top: -100%;
    left: 32.5%;
  }
  /*.scr-logo .saberin-creative-image{*/
  /*width:100%;*/
  /*margin-top:-40%;*/
  /*}*/
  .scr-logo-imgs{
    display:initial;
  }

  @media (max-width:991px){
    .scr-logo {
      width:85%;
      left: 7.5%;
    }
  }

  .nav, .mobile-nav, .mobile-menu-button{
    display:none;
  }
}

@media(max-width: 1299px){
  .landing-wrapper {
    .tagline {
      margin-top: 35px;

      p {
        font-size: 20px;
      }
    }
  }
}
