.about-wrapper {
  background-image: url("../images/about/about-background.png");
  background-size:cover;
  background-position:center;
  height:100vh;
  width:100%;
  display: flex;
  flex-direction: column;

  .about-header {
    width:80%;
    margin: 0 auto;
    padding-top:100px;
    img{
      width:100%;
    }

    .tagline-lg {
      font-size: 4rem;
    }

    .tagline-sm {
      top: 2.1rem;
      font-size: 1.2rem;
    }
  }

  .about-info {
    margin-top:0px;
    text-align:center;
    font-size: 1.25rem;
    flex:1;
    display:flex;
    justify-content: center;
    align-items: center;

    .about-computer-image {
      //width: 65%;
      margin: 0 auto;
      padding:25px;
      width: 90%;
      max-height: 90%;
      height: auto;
      max-width: 500px;
    }

    .about-text {
      text-align:center;
      color: #666C71;
      margin-top:0px;
      font-weight:600;
      padding: 5px;
    }

    .services-link{

    }
  }
}

@media (min-width:1799px) {
  .about-wrapper{
    .about-info {
      .about-image-wrapper{
        margin-left:175px !important;
      }
      .about-text{
        font-size: 1.625rem !important;
        width:80% !important;
      }
    }
  }
}
.spanPadding {
  padding: 15px 50px 15px 50px;
}
@media (min-width: 768px) {
  .about-wrapper {
    .about-header {
      .tagline-lg {
        font-size: 7.4rem;
      }

      .tagline-sm {
        top: 3.8rem;
        font-size: 2.2rem;
      }
    }
  }
}

@media (min-width:1299px) {
  .about-wrapper{

    .about-header{
      margin-left: 175px;
      padding-top:175px;
      padding-bottom:11rem;

      .tagline-lg {
        font-size: 7.6rem;
      }

      .tagline-sm {
        top: 3.5rem;
        font-size: 2.5rem;
      }
    }
    .about-info {
      text-align:left;
      font-size: 1.44rem;
      margin-top:0;

      .about-image-wrapper{
        margin-left:150px;
      }

      .about-computer-image {
        //width: 100%;
        padding:0;
      }

      .about-text{
        font-size: 1.44rem;
        margin-top:0;
        //padding-top:95px;
        width:75%;
        text-align:left;
      }
    }
  }
}
