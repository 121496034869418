.our-work-wrapper{
  height:100vh;
  width:100%;
  color:#666C71;
  display: flex;
  flex-direction: column;

  .our-work-header{
    width:80%;
    margin: 0 auto;
    padding-block:100px;
    max-width:1000px;

    .tagline-lg {
      font-size: 3.5rem;
    }

    .tagline-sm {
      top: 1.7rem;
      font-size: 1.1rem;
    }

    img{
      width:100%;
    }
  }

  .work-images{
    width:100%;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:hover{
      transform:scale(1.1);
    }
  }

  .our-work-info{
    max-width:1250px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p{
      font-size: 1rem;
      font-weight: 600;
    }

    .our-work-links{
      margin-top:0px;
    }
  }

  .our-work-page-button-wrapper{
    width:100%;
    text-align:left;
  }
  .work-image-wrapper{
    padding: 5px;
    cursor:pointer;

    &:last-child{
      margin:0 auto;
    }
  }
}

.our-work-preview{
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display:none;
  background:#fff;
  z-index:9999;
  overflow-y:auto;
}

.spanPadding {
  padding: 15px 50px 15px 50px;
}



// *********************** PROJECTS ********************* //

// ~~~~ ALL ~~~~ //
.ram-wrapper, .waw-wrapper, .bbc-wrapper, .sse-wrapper, .htr-wrapper{
  padding-top:125px;
}
.neb-wrapper {
  padding-top:275px;
}
.neb-header, .ram-header, .waw-header, .bbc-header, .sse-header, .htr-header{
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:999;

  img{
    cursor:pointer;
  }
}
.branding{
  margin-left:0;
}

// ~~~~ NEBULAS ~~~~ //
.neb-wrapper{
  margin-bottom:50px;
  color:#89A6BD;

  img{
    max-width:100%;
  }

  h2{
    color:#3E75BA;
    font-size:6.25rem;
    font-weight: 200;
    width: 100%;
  }

  h3{
    color:#29A9E1;
    font-size:1.875rem;
    width: 100%;
  }

  h5{
    color:#29A9E1;
    font-size:1.375rem;
    width: 100%;
  }

  .neb-header{
    background-color: #00f;
    background-image: url('../images/OurWork/neb/nebulas-banner.jpg');
    background-size: auto 100%;
    background-position: bottom;
    padding-bottom: 150px;
    margin-top: -3px;

    > div{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img{
      width:150px;
    }

    .our-work-nav{
      margin-top:50px;
    }

    a{
      color:#fff;
      cursor:pointer;
      padding:0 15px;

      &:hover{
        color:#fff;
        text-decoration:none;
      }
    }
  }

  .neb-logo{
    width:300px;
    margin-top:50px;
  }

  .project-content{

    .primary-logo img{
      width:150px;
    }

    .alternative-logo img{
      width:200px;
    }

    .color-palette img{
      width: 200px;
    }

    .color-variation {
      margin-top:25px;

      img {
        width: 300px;
      }
    }

    .space-between{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 300px;
    }

    a {
      color:#89A6BD;
    }
  }
  a:focus {
    border-color: #000;
    border-style: solid;
    border-width: .5px;
  }

}

// ~~~~ RAM ~~~~ //
.ram-wrapper{
  margin-bottom:50px;
  color:#7d92a3;

  img{
    max-width:100%;
  }

  h1{
    color:#25425c;
    font-size:6.25rem;
    width: 100%;
    font-weight: 200;
  }

  h3{
    color:#25425c;
    font-size:1.875rem;
    width: 100%;
  }

  h4{
    color:#25425c;
    font-size:1.375rem;
    width: 100%;
  }

  .ram-header{
    background-color: #25425c;
    margin-top:-3px;
    height:125px;

    > div{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img{
      width:150px;
    }

    .our-work-nav{
      margin-top:50px;
    }

    a{
      color:#fff;
      cursor:pointer;
      padding:0 15px;

      &:hover{
        color:#fff;
        text-decoration:none;
      }
    }
  }

  .project-content{

    .ram-logo {
      width: 150px;

      img {
        width: 100%;
      }
    }

    a {
      color:#7d92a3;
    }

    .web-design{

      img{
        width:100%;
      }
    }

    .space-between{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .primary-logo img{
      width:250px;
    }

    .color-palette img{
      width: 350px;
    }

    .color-variation img{
      width:350px;
    }
  }
  a:focus {
    border-color: #fff;
    border-style: solid;
    border-width: .5px;
  }
}

// ~~~~ WAW ~~~~ //
.waw-wrapper{
  margin-bottom:50px;
  color:#717177;

  .color-orange{
    color:#fbaf42;
  }

  img{
    max-width:100%;
  }

  h2{
    color:#26a7df;
    font-size:6.25rem;
    width: 100%;
    font-weight: 200;
  }

  h3{
    color:#26a7df;
    font-size:1.875rem;
    width: 100%;
  }

  h4{
    color:#26a7df;
    font-size:1.375rem;
    width: 100%;
  }

  .waw-header{
    background-color: #26a7df;
    margin-top:-3px;
    height:7.8rem;

    > div{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img{
      width:150px;
    }

    .our-work-nav{
      margin-top:50px;
    }

    a{
      color:#fff;
      cursor:pointer;
      padding:0 15px;

      &:hover{
        color:#fff;
        text-decoration:none;
      }
    }
  }

  .project-content{

    .waw-logo {
      width: 400px;

      img {
        width: 100%;
      }
    }

    a {
      color:#7d92a3;
    }

    .web-design{

      img{
        width:100%;
      }
    }

    .space-between{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .primary-logo img{
      width:250px;
    }

    .alternative-logo img{
      width:250px;
    }

    .color-palette img{
      width: 300px;
    }

    .color-variation img{
      width:300px;
    }
  }
  a:focus {
    border-color: #000;
    border-style: solid;
    border-width: .5px;
  }
}

// ~~~~ BBC ~~~~ //
.bbc-wrapper{
  margin-bottom:50px;
  color:#666C71;

  img{
    max-width:100%;
  }

  h2{
    color:#CEE1E4;
    font-size:6.25rem;
    width: 100%;
    font-weight: 200;
  }

  h3{
    color:#34A2A9;
    font-size:1.875rem;
    width: 100%;
  }

  h4{
    color:#34A2A9;
    font-size:1.375rem;
    width: 100%;
  }

  .bbc-header{
    margin-top:-3px;
    height:125px;
    background-color: #f9b2d3;

    > div{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img{
      width:150px;
    }

    .our-work-nav{
      margin-top:50px;
    }

    a{
      color:#fff;
      cursor:pointer;
      padding:0 15px;

      &:hover{
        color:#fff;
        text-decoration:none;
      }
    }
  }

  .project-content{

    .bbc-logo {
      width: 400px;

      img {
        width: 100%;
      }
    }

    a {
      color:#7d92a3;
    }

    .web-design{

      img{
        width:100%;
      }
    }

    .space-between{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .base-design img{
      width:300px;
    }

    .color-palette img{
      width: 150px;
    }

    .color-variation img{
      width:750px;
    }
  }
  a:focus {
    border-color: #000;
    border-style: solid;
    border-width: .5px;
  }
}

// ~~~~ SSE ~~~~ //
.sse-wrapper{
  margin-bottom:50px;
  color:#5a6c7a;


  h2{
    color:#5a6c7a;
    font-size:6.25rem;
    width: 100%;
    font-weight: 200;
  }

  h3{
    color:#5a6c7a;
    font-size:1.875rem;
    width: 100%;
  }

  h4{
    color:#5a6c7a;
    font-size:1.375rem;
    width: 100%;
  }

  .grey-background{
    background-color:#333333;
    color:#fff;

    h2{
      color:#ffcc05;
    }

    h3{
      color:#ffcc05;
    }

    h4{
      color:#5a6c7a;
    }
  }

  img{
    max-width:100%;
  }

  .sse-header{
    margin-top:-3px;
    height:125px;
    background-image: url('../images/OurWork/sse/SSE-Banner.jpg');
    background-size: cover;

    > div{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img{
      width:150px;
    }

    .our-work-nav{
      margin-top:50px;
    }

    a{
      color:#fff;
      cursor:pointer;
      padding:0 15px;

      &:hover{
        color:#fff;
        text-decoration:none;
      }
    }
  }

  .project-content{

    .sse-logo {
      width: 250px;
      padding-top:50px;

      img {
        width: 100%;
      }
    }

    a {
      color:#fff;
    }

    .web-design{

      img{
        width:100%;
      }
    }

    .space-between{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .primary-logo img{
      width:200px;
    }

    .alternative-logo img{
      width:150px;
    }

    .color-palette img{
      width: 250px;
    }

    .color-variation img{
      width:300px;
    }
  }
  a:focus {
    border-color: #fff;
    border-style: solid;
    border-width: .5px;
  }
}

// ~~~~ HTR ~~~~ //
.htr-wrapper{
  margin-bottom:50px;
  color:#5a6c7a;


  h2{
    color:#3095CA;
    font-size:7.81rem;
    width: 100%;
    font-weight: 200;
  }

  h3{
    color:#1CB87C;
    font-size:2.25rem;
    width: 100%;
  }

  h4{
    color:#3095CA;
    font-size:1.625rem;
    width: 100%;
    text-transform: uppercase;
  }

  img{
    max-width:100%;
  }

  .htr-header{
    margin-top:-3px;
    height:125px;
    background:#3095CA;

    > div{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img{
      width:150px;
    }

    .our-work-nav{
      margin-top:50px;
    }

    a{
      color:#fff;
      cursor:pointer;
      padding:0 15px;

      &:hover{
        color:#fff;
        text-decoration:none;
      }
    }
  }

  .project-content{

    .htr-logo {
      width: 250px;
      padding-top:50px;

      img {
        width: 100%;
      }
    }

    a {
      color:rgb(90, 108, 122);
    }

    .web-design{

      img{
        width:100%;
      }
    }

    .space-between{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .primary-logo img{
      width:200px;
      margin-top:25px;
    }

    .color-palette img{
      width: 250px;
      margin-top:25px;
    }

    .color-variation img{
      width:100%;
      margin-top:25px;
    }
  }
  a:focus {
    border-color: #000;
    border-style: solid;
    border-width: .5px;
  }
}

@media (min-width: 768px) {
  .our-work-wrapper {
    .our-work-header {
      .tagline-lg {
        font-size: 7rem;
      }

      .tagline-sm {
        top: 3.4rem;
        font-size: 2.2rem;
      }
    }
  }
}

@media (min-width:1299px) {
  .our-work-wrapper{

    .our-work-header {
      margin-left: 175px;
      padding-top:125px;
      padding-bottom:7.8rem;

      .tagline-lg {
        font-size: 8rem;
      }

      .tagline-sm {
        top: 3.9rem;
        font-size: 2.5rem;
      }
    }

    .work-images{
    }

    .our-work-info{
      margin-left:175px;
      margin-right:175px;
      width:auto;

      .our-work-links{
        margin-top:0px;
        max-width:75%;
      }

      p{
        font-size:1.625rem;
      }
    }
    .work-image-wrapper{
      padding:10px;

      &:last-child{
        margin:0;
      }
    }
  }
}

@media (min-width:1299px) {
  .our-work-wrapper{

    .our-work-info{
      margin-left:175px;
      margin-right:350px;
    }
  }
}

@media (max-width:1299px) {
  .our-work-links {
    padding: 0 25px;
  }

  .page-button{
    margin-top:0;
  }

  .branding{
    margin-left:0;
  }
  .m-mb-25{
    margin-bottom:25px;
  }

  .project-wrapper {

    h2{
      font-size:2.25rem;
    }
    h3{
      font-size:1.125rem;
    }
    h5{
      font-size:1rem;
    }

    .neb-header, .ram-header, .waw-header, .bbc-header, .sse-header, .htr-header {

      > div {
        flex-direction: column !important;
        font-size:0.875rem;
      }

      .our-work-nav{
        margin-top:0px;
        padding-bottom:10px;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
      }

      a{
        padding:0 5px;
      }
    }

    .neb-header{

      .our-work-nav{
        margin-top:15px;
        padding-bottom:0;
      }
    }

    .project-content {

      .neb-logo {
        width: 200px;
      }

      .ram-logo {
        width: 100px;
      }

      .waw-logo {
        width: 200px;
      }

      .bbc-logo {
        width: 200px;
      }

      .sse-logo {
        width: 150px;
      }

      .htr-logo{
        width:200px;
        padding-top:0;
      }
    }
  }

  .waw-wrapper{

    .alternative-logo{
      margin-top:50px;
    }
    .color-palette{
      margin-top:50px;
    }
  }

  .sse-wrapper{

    .color-variation{
      margin-top:50px;
    }
  }
}
