

/*Footer*/
/*****************************************************************************************************/
#footer{
  position:absolute;
  bottom:0;
  height:$footer_height;
  background-color:$clr_secondary;
  color:$clr_white;
  width:100%;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;

  a{
    color:$clr_white;
    &:hover{
      color:$clr_white;
    }
  }
}

#aaMenu {
  --menu-tab-spacing: 0.8em;

  position: fixed;
  bottom: 0;
  left: 0;
//  background-color: #35a;
  background-color: #d53b10;
  border-top-right-radius: 25px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  font-size: 14px;
  z-index: 1;
}

#aaMenu .menu-title {
  width: 100%;
  font-size: 1.6em;
  padding: 1em 1em 0.8em 1em;
  font-weight: 700;
//  background-color: #fffd;
  background-color: #F27250;
  text-align: center;
  border: none;
  border-top-right-radius: 25px;
  outline: none;
}

#aaMenu .menu-title:focus {
  box-shadow: inset 0 0 1px 4px #35e3;
}

#aaMenu .menu-tabs {
  max-height: 50vh;
  width: 30em;
  max-width: 100vw;
  padding: var(--menu-tab-spacing);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #fffe #3355aa;

  transition: all 300ms ease-in-out;
}

/* Chrome, Edge, and Safari */
#aaMenu .menu-tabs::-webkit-scrollbar {
  width: 13px;
}

#aaMenu .menu-tabs::-webkit-scrollbar-track {
//  background: #3355aa;
  background: #d53b10;
}

#aaMenu .menu-tabs::-webkit-scrollbar-thumb {
  background-color: #fffe;
  border-radius: 15px;
//  border: 3px solid #3355aa;
  border: 3px solid #d53b10;
}

#aaMenu .card {
  background-color: #fff;
}

.btn-block {
//  background-color: #3484;
  background-color: #f37a59;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 200ms ease-in-out;
  text-decoration: none;
}

#aaMenu .collapse .fa-arrow-up {
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-block:focus,
.btn-block:active,
.btn-block:hover {
  text-decoration: none;
//  background-color: #3344853a;
  background-color: #a62e0c;
  color: #FFF;
}

.btn-block:focus,
.btn-block:active {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.btn-block span {
  font-weight: 700;
  font-size: 1.2em;
}

#aaMenu .card.tab:focus-within {
  box-shadow: 0 0 1px 4px #fff5;
}

#aaMenu .card-body {
  padding: .1em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#aaMenu .staticFontSize {
  font-size: 1.2em;
}

#aaMenu .staticBottomMargin {
  margin-bottom: .5em;
}

#aaMenu .btn-override {
  font-size: 16px;
  padding: 0.375em 0.75em;
}

#aaMenu .btn-no-visit:focus {
  outline: none;
  box-shadow: none;
}

#aaMenu .page-button {
  transition: all 200ms ease-in-out;
}

#aaMenu .page-button:focus {
  border: 1px solid #0ffa;
  box-shadow: 0 0 2px 2px #fd1d1d66;
  transform: scale(1.05);
}

#aaMenu .card-body .control-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-height: 8em;
}

#aaMenu .card.tab:hover {
  background-color: #fff;
}

#aaMenu .card.tab+.card.tab {
  margin-top: var(--menu-tab-spacing);
}

.aa-link {
  font-weight: 400;
  color: #FFFFFF;
  text-decoration: none;
}

.aa-function {
  background-color: #f8b3a0;
}

