.nav{
  position:fixed;
  right:175px;
  z-index:999;
  text-align:right;
  height:100%;
  font-size:18px;
  font-family: 'proxima-nova';

  ul{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 50px;
    height:400px;
    width: 350px;
    list-style-type: none;
    color:#666c71;
    letter-spacing:0.2em;

    li{
      cursor: pointer;
      background: url("../images/nav/gray-circle.png");
      padding-right: 40px;
      z-index: 999;
      background-position-x: 100% !important;
      background-position-y: 2.5px!important;
      background-repeat: no-repeat!important;
      background-size: 25px!important;

      .nav-circle{display:none;}

      &.active{
        color:#f05b2a;
        font-weight:600;
        background: url("../images/nav/orange-circle.svg");
      }

      &:hover{
        color:#f05b2a;
        font-weight:bold;
        background: url("../images/nav/hover-circle.svg");
      }
    }
  }
  .nav-a {
    color: #666C71;
    text-decoration: none;

    &:hover{
      color: #f05b2a;
    }
  }

  .nav-border{
    border-left: 1px solid #ccc;
    margin-top: 71px;
    height: 361px;
    margin-left: -13px;
    z-index:99;
  }
}

.mobile-nav{
  display:none;
  position:absolute;
  min-width:150px;
  height:100vh;
  width:50%;
  left:100%;
  background:#fff;
  z-index:1001;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(240,90,38,0.5);
  -moz-box-shadow: 0px 0px 15px 5px rgba(240,90,38,0.5);
  box-shadow: 0px 0px 15px 5px rgba(240,90,38,0.5);

  .mobile-menu-close{
    width:25px;
    position:absolute;
    top:25px;
    right:25px;
    z-index:1002;

    img{
      width:100%;
    }
  }

  ul{
    margin-top: 50px;
    padding:15px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    li{
      padding-bottom: 20px;
    }
  }
}

.mobile-menu-button{
  display:none;
  width:25px;
  position:absolute;
  top:25px;
  right:25px;
  z-index: 1000;

  img{
    width:100%;
  }
}

@media(min-width:1299px) and (max-width:1800px){
  .nav{
    right:40px;
  }
}
@media(max-width:1299px){
  .nav{
    display:none;
  }
  .mobile-menu-button{
    display:block;
  }
}
