.contact-wrapper{
  color:#666C71;
  height:100vh;
  width:100%;
  background-image: url("../images/contact/background.png");
  background-size:cover;
  background-position: center;

  a{
    color:#666c71;
  }
  a:focus {
    border-color: #000;
    border-style: solid;
    border-width: .5px;
  }

  h2{
    font-weight:bold;
    font-size:1.375rem;
    color:#0c4e68;

    &:after{
      border-bottom:4px solid #F05A26;
      content:"";
      display:block;
      width:50px;
    }
  }

  .contact-info{
    display:flex;
    flex-direction: column;
    margin-top:15px;
    width: auto;
    height: 100%;

    .contact-header{
      padding-top:50px;
      max-width:100%;
      flex: 1;

      .tagline-lg {
        top: 20px;
        font-size: 4.5rem;
        color: #ff4a33;

        background: linear-gradient(to right, #ee3e3d, #f27250);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .tagline-sm {
        font-size: 2rem;
      }


      img{
        width:100%;
      }
    }

    .contact-info-wrapper{
      display: flex;
      flex-direction: row;
      flex: 1.25;
      font-size:1rem;
      font-weight:600;

      p{
        font-size:1rem;
        font-weight:600;
      }
    }

    .contact-location-info{
      margin-left:40px;

      .contact-icon{
        margin-left:-40px;
        padding-right:20px;

        img{
          width:15px;
        }
      }
      .contact-location-icon{
        margin-left:-45px;
        padding-right:10px;

        img{
          width:25px;
        }
      }
    }

    .contact-copy{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align:center;

      p {
        font-size: 0.625rem;
        font-weight: 600;
      }
    }
  }

  .follow-us-icons{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    width:200px;

    img{
      height:20px;
    }
  }
}

@media (min-width: 768px) {
  .contact-wrapper {
    .contact-info {
      .contact-header {
        .tagline-sm {
          font-size: 3rem;
        }

        .tagline-lg {
          top: 1.7rem;
          font-size: 7rem;
        }
      }
    }
  }
}


@media (min-width:1299px) {
  .contact-wrapper{

    h2{
      font-size:3.625rem;

      &:after{
        width:200px;
      }
    }

    .contact-info {
      margin-left:175px;
      margin-right:175px;

      .contact-info-wrapper{
        font-size:1.625rem;
        margin-top:50px;

        p{
          font-size:1.625rem;
        }
      }

      .contact-header {
        padding-top:175px;
        padding-bottom:11rem;

        .tagline-lg {
          font-size: 7.5rem;
        }

        .tagline-sm {
          top: -1rem;
          font-size: 3rem;
        }
      }

      .contact-copy{
        p{
          font-size:1rem;
        }
      }
    }

    .follow-us-icons{
      display:flex;
      flex-direction:row;
      justify-content: space-between;
      width:40%;
      min-width:200px;

      img{
        height:35px;
      }
    }
  }
}

@media (min-width:1299px) and (max-width:1420px) {
  .contact-wrapper {

    h2 {
      font-size: 2.25rem;

      &:after{
        width:125px;
      }
    }
  }
}
