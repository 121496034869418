
/*Fonts*/
/*****************************************************************************************************/
:root {
  --base-font-size: 12px;

  font-size: var(--base-font-size);
}
@media (min-width: 768px) {
  :root {
    font-size: calc(var(--base-font-size) + 2px);
  }
}
@media (min-width: 1299px) {
  :root {
    font-size: calc(var(--base-font-size) + 4px);
  }
}

p{
  //font-size:$fs_normal_text;
}
h2{
  //font-size:$fs_h2;
  font-size:  calc(var(--base-font-size) + 20px);
}
h3{
  //font-size:$fs_h3;
  font-size:  calc(var(--base-font-size) + 16px);
}
h4{
  //font-size:$fs_h4;
  font-size:  calc(var(--base-font-size) + 13px);
}
h5{
  //font-size:$fs_h5;
  font-size:  calc(var(--base-font-size) + 10px);
}
h6{
  //font-size:$fs_h6;
  font-size:  calc(var(--base-font-size) + 6px);
}

/*General*/
/*****************************************************************************************************/
html{
  height:100vh;
}

html, body{
  margin:0;
  padding:0;
}

body{
  width:100%;
  height:100%;
  position:fixed;
  font-size: 1rem;
  font-family: 'proxima-nova';
  overflow:hidden;
}

.body-content{
  width: 100%;
  height:100%;
}

.flex{
  display:flex;
}

ul {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0;
  }
}

.page-list{
  list-style:none;
  width: 100%;

  & > li{
    height:100vh;
    width: 100%;
  }
}

.scr-logo{
  position: absolute;
  top:25px;
  left:25px;
  width:150px;
  z-index: 999;
}
.scr-logo-imgs{
  display:none;
}
.scr-header-logo{
  display:block;
  position:absolute;
  cursor:pointer;
  width:100%;
  left:0;
  top:0;
}

.page-button{
  font-size: 0.875rem;
  font-weight:bold;
  border-radius:50px;
  padding:10px 30px;
  color:#fff;
  margin-top:25px;
  cursor:pointer;
  border:none;
  background: rgb(253,29,29);
  background: -moz-linear-gradient(90deg, rgba(238,57,59,1) 0%, rgb(234, 106, 72) 100%);
  background: -webkit-linear-gradient(90deg, rgba(238,57,59,1) 0%, rgb(234, 106, 72) 100%);
  background: linear-gradient(90deg, rgba(238,57,59,1) 0%, rgb(234, 106, 72) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fd1d1d",endColorstr="#fcb045",GradientType=1);
}

.page-button-slim {
  padding: 2px 15px;
  margin-top: 5px;
  border: none;
  outline: none;
  max-height: 5em;
  font-size: 1em;

  &:hover {
    color: #FFF;
    text-decoration: none;
    transform: scale(1.3);
  }
  &:visited {
    outline: none;
  }
}


a:focus, button:focus {
  outline: 0;
}

@media (min-width:1299px){
  .scr-logo {
    top: 50px;
    left: 175px;
  }

  .page-button{
    display:inline-block;
    font-size: 1.17rem;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:hover{
      transform:scale(1.1);
    }
  }
}

/*Margins*/
/*****************************************************************************************************/
.mt-10{
  margin-top:10px;
}
.mt-25{
  margin-top:25px;
}
.mt-50{
  margin-top:50px;
}
.mt-100{
  margin-top:100px;
}
.mb-25{
  margin-bottom:25px;
}

/*Page Taglines*/
/*************************/
.tagline-lg {
  position: absolute;
  text-transform: uppercase;
  font-size: 6.2rem;
  color: #8D9396;
  font-weight: 900;
}

.tagline-sm {
  position: absolute;
  font-size: 1.2rem;
  font-weight: 500;
  color: #f26a3f;
}

@media (min-width: 1299px) {
  .tagline-lg {
    font-size: 6.2rem;
  }

  .tagline-sm {
    font-size: 2.6rem;
    top: -17px;
  }
}
/*keypress css */
/*****************************************************************************************************/

.grow {
  transform:scale(1.1);
}

.skipNavigation {
  color: #fff;
  visibility: hidden;
  background-color: #f26a3f;
  padding: 5px 25px 2px 25px;
  margin: 10px;
}
/*bootstrap accordion */
/*****************************************************************************************************/
#accordion{
  width:400px;
  position:absolute;
  bottom:0;
 }

