#CanvasWrapper{
  width:100%;
  height:100%;
  position:absolute;
  z-index: -999;
}

.home-wrapper {
  height:100%;
  min-height: 675px;
  margin-left:175px;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  .home-titles-wrapper{
    width:auto;
  }

  .lets-do-something-title{
    font-size:2.625rem;
    font-weight:600;
    color:#0C4E68;
  }

  .creative-title{
    margin-top: -50px;
    font-size:8.875rem;
    font-weight:bold;
    background: rgb(242,114,80);
    background: -moz-linear-gradient(90deg, rgba(234, 106, 72, 1) 0%, rgba(238,57,59,1) 100%);
    background: -webkit-linear-gradient(90deg, rgb(234, 106, 72) 0%, rgba(238,57,59,1) 100%);
    background: linear-gradient(90deg, rgb(234, 106, 72) 0%, rgb(238, 57, 59) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .tag-line{
    padding-left: 10px;
    padding-right: 10px;
  }

  img, .home-button-wrapper{
    margin: 50px 0;
  }

  &> p{
    width:50%;
    font-size:1.625rem;
    font-weight:600;
    color: #666C71;
    margin: -25px 0 25px 0;
  }

  .home-button-wrapper{

    .call-button{
      background: rgba(238,57,59,1);
      padding-left:50px;
      padding-right:50px;

      &:hover{
        color:#fff;
        text-decoration: none;
      }

    }

    .email-button{
      background: #F15A24;
      padding-left:50px;
      padding-right:50px;

      &:hover{
        color:#fff;
        text-decoration: none;
      }

    }

  }
}

.spanPadding {
  padding: 15px 50px 15px 50px;
}

@media (max-width:1299px){
  #CanvasWrapper{
    display:none;
  }

  .home-wrapper{
    background-image: url('../images/home/SCR-icon-Gray.png');
    backgorund-size:contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 25%;
    margin-left:0px;

    .home-titles-wrapper{
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }

    & > p{
      width:100%;
      font-size:1rem;
      text-align:center;
      margin: 25px 0;
    }

    .lets-do-something-title{
      font-size:1.8em;
    }

    .creative-title{
      font-size:5em;
    }

    .home-button-wrapper{
      width: 100%;
      text-align: center;

      .email-button {
        margin-left:25px;
      }
    }
  }
}
