$pageChangeSpeed: 2s;

.previous-page-above {
  position:absolute;
  height:100vh;
  animation:         slide-out-top $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
}

.next-page-above{
  height:100vh;
  position:absolute;
  animation:         slide-in-top $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
}

.previous-page-below {
  position:absolute;
  height:100vh;
  animation:         slide-out-bottom $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
}

.next-page-below{
  height:100vh;
  position:absolute;
  animation:         slide-in-bottom $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
}

.animate-logo-out{
  position:absolute;
  animation:         animate-logo-out $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */

  .saberin-creative-logo{
    animation:         animate-logo-out-move-logo $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
    animation-timing-function: linear;
  }

  .saberin-text-img{
    animation:         animate-logo-out-tighten-image $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
  }

  .creative-text-img{
    animation:         animate-logo-out-align-image $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
  }

  .scr-header-logo{
    position:absolute;
    left:0;
    top:0;
    display:block !important;
    animation:         fade_in $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
  }
}

.animate-logo-in{
  position:absolute;
  animation:         animate-logo-out $pageChangeSpeed reverse; /* IE 10+, Fx 29+ */

  .saberin-creative-logo{
    animation:         animate-logo-out-move-logo $pageChangeSpeed reverse; /* IE 10+, Fx 29+ */
    animation-timing-function: linear;
  }

  .saberin-text-img{
    animation:         animate-logo-out-tighten-image $pageChangeSpeed reverse; /* IE 10+, Fx 29+ */
  }

  .creative-text-img{
    animation:         animate-logo-out-align-image $pageChangeSpeed reverse; /* IE 10+, Fx 29+ */
  }

  .scr-header-logo{
    position:absolute;
    left:0;
    top:0;
    display:block !important;
    animation:         fade_in $pageChangeSpeed reverse; /* IE 10+, Fx 29+ */
  }
}

.active-menu{
  display:block;
  left:100%;
  animation:         mobile-menu-slide-in 0.75s forwards; /* IE 10+, Fx 29+ */
}

.close-menu{
  display:block;
  left:50%;
  animation:         mobile-menu-slide-out 0.75s forwards; /* IE 10+, Fx 29+ */
}

.animate-service-info-left{
  left:100%;
  animation:         service-info-slide-in 0.5s forwards; /* IE 10+, Fx 29+ */
}

.animate-service-info-right{
  left:60%;
  animation:         service-info-slide-out 0.5s forwards; /* IE 10+, Fx 29+ */
}
.animate-landing-page-arrow{
  bottom:150px;
  animation:         landing-page-arrow-bounce 1s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.animate-tagline-in{
  opacity:0;
  animation:         tageline-fade-in $pageChangeSpeed forwards; /* IE 10+, Fx 29+ */
}
.animate-tagline-out{
  //opacity:1;
  animation:         tageline-fade-in $pageChangeSpeed reverse; /* IE 10+, Fx 29+ */
}

//Key Frames:

@-webkit-keyframes slide-out-top {
  0%   { margin-top: 0; }
  100% { margin-top: -100vh; }
}
@-webkit-keyframes slide-out-bottom {
  0%   { margin-top: 0; }
  100% { margin-top: 100vh; }
}

@-webkit-keyframes slide-in-top {
  0%   { margin-top: -100vh; }
  100% { margin-top: 0; }
}
@-webkit-keyframes slide-in-bottom {
  0%   { margin-top: 100vh; }
  100% { margin-top: 0; }
}
@-webkit-keyframes animate-logo-out {
  0%   { left: calc(50% - 200px); top:40%; width:400px; }
  100% { left: 175px; top: 50px; width:150px; }
}
@-webkit-keyframes animate-logo-out-move-logo {
  0%   { top:-100%; left:32.5%; position:absolute; }
  33%  { left: 3px; top:-100%; }
  66%  { top: -25%; }
  90%  { transform:rotate(-360deg)}
  100% { top:2px; left: 3px; width:37px; transform:rotate(-360deg) }
}
@-webkit-keyframes animate-logo-out-tighten-image {
  0%   { margin-bottom:25px; margin-left:0; width:100%; margin-top:0;}
  95%  { opacity: 1; }
  100% { margin-bottom:-6px; margin-left:43px; width: 100px; margin-top:-7px; opacity: 0; }
}
@-webkit-keyframes animate-logo-out-align-image {
  0%   { margin-left:0; width:80%; margin-top:0 }
  95%  { opacity: 1; }
  100% { margin-left:8px; width: 63px; margin-top:-7px; opacity: 0; }
}
@-webkit-keyframes fade_in {
  0%   { opacity:0;}
  95%  { opacity:0;}
  100% { opacity: 1;}
}
@-webkit-keyframes mobile-menu-slide-in {
  0%   { left: 100%; }
  100% { left: 50%; }
}
@-webkit-keyframes mobile-menu-slide-out {
  0%   { left: 50%; }
  100% { left: 110%; } //extra percentage to have the box shadow slide out as well
}
@-webkit-keyframes service-info-slide-in {
  0%   { left: 100%; }
  100% { left: 60%; }
}
@-webkit-keyframes service-info-slide-out {
  0%   { left: 60%; }
  100% { left: 100%; }
}
@-webkit-keyframes landing-page-arrow-bounce {
  0%   { bottom:150px; }
  100% { bottom:50px; }
}
@-webkit-keyframes tageline-fade-in {
  0%   { opacity:0; }
  85%  { opacity:0; }
  100% { opacity:1; }
}

@media(max-width:991px){
  @-webkit-keyframes animate-logo-out {
    0%   { left: 7.5%; top:40%; width:85%; }
    100% { left: 25px; top: 25px; width:150px; }
  }
}
