.services-wrapper{
  height:100vh;
  width:100%;
  display: flex;
  flex-direction: column;

  .services-header {
    width:80%;
    margin: 0 auto;
    padding-top:100px;

    img{
      width:100%;
    }

    .tagline-lg {
      font-size: 3rem;
    }

    .tagline-sm {
      top: 2rem;
      left: 9.5rem;
      font-size: 1.6rem;
    }
  }
  .service-info-wrapper{
    width:auto;
    padding-left:30px;
    padding-right:30px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    //.page-button{
    //  position: absolute;
    //  bottom: 0;
    //}

    .mobile-services-info{
      display: none;
      position:absolute;
      border-radius:15px;
      background: rgba(0,0,0,0.85);
      color: #fff;
      left: 5%;
      bottom: 10%;
      right: 5%;
      height: 80%;
      padding:25px;
      -webkit-box-shadow: 0px 0px 15px 0px rgba(240,90,38,0.5);
      -moz-box-shadow: 0px 0px 15px 0px rgba(240,90,38,0.5);
      box-shadow: 0px 0px 15px 0px rgba(240,90,38,0.5);

      > div{
        display:none;
        height:100%;
      }

      > div:first-child{
        display:initial
      }
      //background-color:red;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height:100%;

        li {
          font-size: 14px;
        }

        li::before {
          color: #F05A26;
          content: "\2022";
        }
      }

      .mobile-services-info-close{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;

        img{
          width:100%;
        }
      }
    }
  }

  .service-info{
    display:flex;
    flex-direction: column;
    margin-top:0px;
    cursor:pointer;
    padding: 0;
    align-content: space-between;
    justify-content: center;
    //align-items: flex-start;
    //justify-items: center;



    .service-list-wrapper{
      display:none;
    }

    .service-title{
      color:#0C4E68;
      font-size:16px;
    }

    .service-image{
      height:100px;

      img{
        height:100%;
      }
    }

    ul{
      li{
        font-size:14px;
      }
    }

    li::before{
        color: #F05A26;
        content: "\2022";
    }
  }
  .desktop-services-info {
    width: 100%;
    z-index: 999!important;
    min-height:100px;

    @keyframes fadein {
      from { opacity: 0;
        //transform: translateY(-30px);
        }
      to {
        opacity: 1;
        //transform: translateY(0);
      }
    }

    .desktop-support {
      display: none;
      z-index: 999!important;

      .service-list-wrapper{
        z-index: 999!important;
        background-color: white;

        font-size: 1rem;
        margin-top: 25px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 15px;
        width: 22%;
        //margin-left:-20px;
        height: 250px;
        justify-content: center;
        align-items: center;
        display: flex;
        -webkit-box-shadow: 0px 10px 11px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 10px 11px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 10px 11px -4px rgba(0,0,0,0.75);
      }

      &.corporate{
        overflow:visible;
        z-index: 999!important;

        .service-list-wrapper {
          margin-left: 0;
          margin-top:-200px;
        }
      }

      &.web-design{
        overflow:visible;
        z-index: 999!important;

        .service-list-wrapper {
          margin-left: 25%;
          margin-top:-200px;
        }
      }
      &.web-dev{
        overflow:visible;
        z-index: 999!important;

        .service-list-wrapper {
          margin-left: 50%;
          margin-top:-200px;
        }
      }
      &.support{
        overflow:visible;
        z-index: 999!important;

        .service-list-wrapper {
          margin-left: 77%;
          margin-right:-100px;
          margin-top:-200px;
        }
      }
    }
  }
}

.service-list{
  display: none;
  font-size: 1rem;
  text-align: center;
}

.half-opacity-mobile{
  opacity:0.5;
}

.spanPadding {
  padding: 15px 50px 15px 50px;
}

@media (min-width: 768px) {
  .services-wrapper {
    .services-header {
      .tagline-lg {
        font-size: 6rem;
      }

      .tagline-sm {
        top: 4rem;
        left: 19.3rem;
        font-size: 3.1rem;
      }
    }
  }
}

@media (min-width:1299px) and (max-width:1799px) {
  .service-info-wrapper{
    margin-left: 90px !important;
  }
  .service-row{
    margin-right: 0 !important;
  }
}

@media (min-width:1299px) {
  .d-mr-100{
    margin-right:100px;
  }

  .support-icon {
    padding-left:20%;
    z-index: 1000;
  }

  .half-opacity-mobile{
    opacity:1;
  }

  .mobile-services-info{
    display:none !important;
  }
  .service-container{
    text-align:center;
    min-height: 254px;
  }
  .service-container:hover {

    img, h3{
      display:inline-block;
      width:auto;
      //transform:scale(1.1);
    }
  }

  .services-wrapper{

    .service-info-wrapper{
      margin-top:50px;
      margin-left:175px;
      margin-right:15%;

      .page-button{
        position:initial;
      }
      .service-info{
        margin-top:0;

        &:first-child{
          opacity:1;

          .service-title{
            color:#f05b2a;
          }
        }

        .service-list-wrapper{
          display:initial;
        }

        .service-title {
          font-size:2rem;
          font-weight:bold;
        }

        .service-image{
          height:100px;
        }

        ul {

          li{
            padding-top:10px;
            font-size:1.37rem;
          }
        }
      }
    }

    .services-header {
      margin-left: 175px;
      padding-block:175px;

      .tagline-lg {
        font-size: 6rem;
      }

      .tagline-sm {
        top: 4rem;
        left: 19.2rem;
        font-size: 3.4rem;
      }
    }

    .desktop-services-info{
      .desktop-support{
        display:none;

        &:first-child{
          //display:block;
          // displays info on page load
        }
      }
    }
  }
}

@media (min-width:1299px) and (max-height: 768px) {
  .services-wrapper {

    .service-info-wrapper {

      .service-info {

        .service-title {
          font-size: 1.25rem;
        }

        ul {

          li {
            padding-top: 10px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width:1299px){
  .service-info{
    text-align:center;
    margin-top:15px;
  }

  .button-row{
    text-align: center;
  }

  .services-wrapper{
    .desktop-services-info{
      display:none !important;
    }
  }
}
